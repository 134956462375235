<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="4"
      >
        <base-material-card color="#174282">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Connexion
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    label="Adresse e-mail"
                    class="purple-input"
                    v-model="email"
                    type="email"
                    color="#174282"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    label="Mot de passe"
                    class="purple-input"
                    v-model="password"
                    type="password"
                    color="#174282"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="#174282"
                    class="mr-0"
                    @click="login"
                  >
                    Connexion
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <router-link :to="{ name: 'Inscription'}" class="link">Créer un compte</router-link>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { httpPost } from '@/plugins/axios'
  export default {
    name: 'Login',
    data () {
      return {
        snackbar: false,
        text: '',
        render: 0,
        timeout: 2000,
        email: '',
        password: '',
        error: false,
      }
    },
    methods: {
      async login () {
        const user = await httpPost('connexion', {
          email: this.email,
          password: this.password,
        })
        if (user.Result === 'OK' && user.UserData) {
          this.$store.dispatch('modifyUser', {
            id: user.UserData.USR_ID,
            name: user.UserData.USR_Name,
            lastName: user.UserData.USR_Surname,
            email: user.UserData.USR_Mail,
            isAdmin: user.UserData.USR_Admin,
          })
          this.$router.push('/')
        } else {
          this.text = 'Mot de passe et Email ne correspondent pas'
          this.snackbar = true
        }
      },
    },
  }
</script>
<style lang="sass">
.link
  color: #174282!important
</style>
